@use "@angular/material" as mat;
@use "sass:map";
@use "sass:color";

// Note: Color palettes are generated from primary: #1bb2ba, secondary: #b23263
$_palettes: (
  primary: (0: #000000,
    10: #002022,
    20: #003739,
    25: #004346,
    30: #004f53,
    35: #005c60,
    40: #00696e,
    50: #00848b,
    60: #00a0a8,
    70: #1dbdc6,
    80: #4cd9e2,
    90: #6ff6fe,
    95: #c1fbff,
    98: #e7feff,
    99: #f3ffff,
    100: #ffffff,
  ),
  secondary: (0: #000000,
    10: #3f001b,
    20: #650030,
    25: #7a003b,
    30: #8b0f46,
    35: #9b1e52,
    40: #ab2c5e,
    50: #cb4676,
    60: #ec5f90,
    70: #ff84aa,
    80: #ffb1c5,
    90: #ffd9e1,
    95: #ffecef,
    98: #fff8f8,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (0: #000000,
    10: #081c36,
    20: #20314c,
    25: #2b3c58,
    30: #374764,
    35: #425370,
    40: #4e5f7d,
    50: #677897,
    60: #8091b1,
    70: #9baccd,
    80: #b6c7e9,
    90: #d6e3ff,
    95: #ecf0ff,
    98: #f9f9ff,
    99: #fdfbff,
    100: #ffffff,
  ),
  neutral: (0: #000000,
    10: #191c1c,
    20: #2d3131,
    25: #383c3c,
    30: #444747,
    35: #505353,
    40: #5c5f5f,
    50: #747878,
    60: #8e9191,
    70: #a9acac,
    80: #c4c7c7,
    90: #e0e3e3,
    95: #eff1f1,
    98: #f7faf9,
    99: #fafdfc,
    100: #ffffff,
    4: #0b0f0f,
    6: #101414,
    12: #1d2020,
    17: #272b2b,
    22: #323536,
    24: #363a3a,
    87: #d8dada,
    92: #e6e9e8,
    94: #eceeee,
    96: #f2f4f4,
  ),
  neutral-variant: (0: #000000,
    10: #141d1e,
    20: #293233,
    25: #343d3e,
    30: #3f4949,
    35: #4b5455,
    40: #566061,
    50: #6f7979,
    60: #899393,
    70: #a3adae,
    80: #bec8c9,
    90: #dae4e5,
    95: #e9f3f3,
    98: #f1fbfc,
    99: #f4fefe,
    100: #ffffff,
  ),
  error: (0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

// Make adjustments to the default typography:
// - Font-size 14px instead of 16px
// - Table headers as 12px instead of 14px
// - Decrease letter spacing of buttons & tabs
$vrianalyzer-typography: (
  // use-system-variables: true,
  // body-1: (font-size: 14px),
  // body-2: (font-size: 14px),
  // subtitle-1: (font-size: 14px),
  // subtitle-2: (font-size: 12px),
  // button: (letter-spacing: 0.75px)
);

$vrianalyzer-theme-colors: (
  theme-type: light,
  primary: $_primary,
  // secondary: $_secondary,
  tertiary: $_tertiary,
  // use-system-variables: true,
);

$vrianalyzer-theme: mat.define-theme((color: $vrianalyzer-theme-colors,
      typography: $vrianalyzer-typography,
      density: (scale:-2)));

// Colors that can be used directly from stylesheets

$theme-color-primary-60: mat.get-theme-color($vrianalyzer-theme, primary, 60);

$theme-color-tertiary-50: mat.get-theme-color($vrianalyzer-theme, tertiary, 50);

$theme-color-text: mat.get-theme-color($vrianalyzer-theme, neutral, 100);

$theme-color-text-25: mat.get-theme-color($vrianalyzer-theme, neutral, 25);

$theme-color-error: mat.get-theme-color($vrianalyzer-theme, error, 50);

/*
$theme-color-primary-lightest: mat.get-color-from-palette($vrianalyzer-palette-primary, 50);
$theme-color-primary-lighter: mat.get-color-from-palette($vrianalyzer-palette-primary, lighter); // 100
$theme-color-primary-light: mat.get-color-from-palette($vrianalyzer-palette-primary, 300);
$theme-color-primary-default: mat.get-color-from-palette($vrianalyzer-palette-primary, default); // 500
$theme-color-primary-darker: mat.get-color-from-palette($vrianalyzer-palette-primary, darker); // 700
$theme-color-primary-darkest: mat.get-color-from-palette($vrianalyzer-palette-primary, 900);

$theme-color-accent-lightest: mat.get-color-from-palette($vrianalyzer-palette-accent, 50);
$theme-color-accent-lighter: mat.get-color-from-palette($vrianalyzer-palette-accent, lighter); // 100
$theme-color-accent-default: mat.get-color-from-palette($vrianalyzer-palette-accent, default); // 500
$theme-color-accent-darker: mat.get-color-from-palette($vrianalyzer-palette-accent, darker); // 700
$theme-color-accent-darkest: mat.get-color-from-palette($vrianalyzer-palette-accent, 900);

// For the default warning color, mix one of the accent colors with one of the warning colors
$theme-color-warn: mix(mat.get-color-from-palette($vrianalyzer-palette-warn, text),
    mat.get-color-from-palette($vrianalyzer-palette-accent, 200));
$theme-color-warn-orange: mix(yellow, $theme-color-warn);

$theme-color-text: mat.get-color-from-palette($vrianalyzer-palette-foreground, text);
$theme-color-text-secondary: mat.get-color-from-palette($vrianalyzer-palette-foreground, secondary-text);
$theme-color-text-hint: mat.get-color-from-palette($vrianalyzer-palette-foreground, hint-text);
$theme-color-text-disabled: mat.get-color-from-palette($vrianalyzer-palette-foreground, disabled-text);
$theme-color-text-disabled-button: mat.get-color-from-palette($vrianalyzer-palette-foreground, disabled-button);
$theme-color-text-base: mat.get-color-from-palette($vrianalyzer-palette-foreground, base);

$theme-color-divider: mat.get-color-from-palette($vrianalyzer-palette-foreground, divider);
$theme-color-formfield-line: mat.get-color-from-palette($vrianalyzer-palette-foreground, divider, 0.42);
$theme-color-hover: mat.get-color-from-palette($vrianalyzer-palette-background, hover);

$theme-color-background: mat.get-color-from-palette($vrianalyzer-palette-background, background);
$theme-color-background-card: mat.get-color-from-palette($vrianalyzer-palette-background, card);
$theme-color-background-dialog: mat.get-color-from-palette($vrianalyzer-palette-background, dialog);
$theme-color-background-toolbar: mat.get-color-from-palette($vrianalyzer-palette-background, app-bar);
$theme-color-background-subtle: #fafafa;
$theme-color-background-grey: #F3F4F6;

$theme-color-button-selected: mat.get-color-from-palette($vrianalyzer-palette-background, selected-button);
$theme-color-button-focused: mat.get-color-from-palette($vrianalyzer-palette-background, selected-focused);
$theme-color-button-disabled: mat.get-color-from-palette($vrianalyzer-palette-background, selected-disabled);
*/
