@use '_theme.scss';

/**
* Base styling; resets and theme. Should be loaded first.
*/

// --------------------------------------------

/**
* https://css-tricks.com/box-sizing/#article-header-id-6
*/
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// --------------------------------------------

html,
body {
  height: 100%;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  margin: 0;
}
